import { Box, Button, Flex, FlexProps, Heading, Text } from "@chakra-ui/react";
import Image, { StaticImageData } from "next/image";
import React from "react";

export const Hero: React.FC<{
  header: React.ReactNode;
  tagline: React.ReactNode;
  buttons: Array<{
    label: React.ReactNode;
    onClick: () => void;
    bg?: string;
    color?: string;
  }>;
  background?: StaticImageData;
  lazyLoadBackground?: boolean;
  backgroundRaw?: string;
  container?: FlexProps;
  textContainer?: FlexProps;
  backgroundStyle?: React.CSSProperties;
}> = ({
  header,
  tagline,
  buttons,
  background,
  backgroundRaw,
  container,
  textContainer,
  backgroundStyle,
  lazyLoadBackground = true,
}) => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      py="8"
      px="2"
      background={backgroundRaw}
      pos="relative"
      {...container}
    >
      {background && (
        <Image
          src={background}
          alt=""
          fill
          style={{
            objectFit: "cover",
            zIndex: -1,
            position: "absolute",
            ...backgroundStyle,
          }}
          loading={lazyLoadBackground ? "lazy" : "eager"}
        />
      )}

      <Flex
        direction="column"
        alignItems="center"
        textAlign="center"
        {...textContainer}
      >
        <Heading
          fontSize={{
            base: "2xl",
            lg: "5xl",
          }}
          maxW={{ base: "90%", lg: "70%" }}
        >
          {header}
        </Heading>
        <Text
          maxW="xl"
          w="full"
          fontSize={{
            base: "sm",
            lg: "lg",
          }}
        >
          {tagline}
        </Text>
        {buttons.length > 0 ? (
          <Flex
            flexDir={{
              base: "column",
              lg: "row",
            }}
            gap="4"
            mt="4"
          >
            {buttons.map((button, i) => (
              <Button
                key={String(button.label)}
                onClick={button.onClick}
                cursor="pointer"
                rounded="full"
                bg={button.bg ? button.bg : i === 0 ? "#8DEBCF" : "gray.200"}
                px={{
                  base: "4",
                  lg: "8",
                }}
                py={{
                  base: "2",
                  lg: "6",
                }}
                fontWeight="normal"
                fontSize={{
                  base: "xs",
                  lg: "lg",
                }}
                color={button.color}
              >
                {button.label}
              </Button>
            ))}
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  );
};
