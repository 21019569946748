import { Section } from "@/components/layout/Section";
import { Button, Flex, Grid, Heading, Text } from "@chakra-ui/react";
import React from "react";
import CARD_IMG_1 from "@/img/home_easy_1.png";
import CARD_IMG_2 from "@/img/home_easy_2.png";
import CARD_IMG_3 from "@/img/home_easy_3.png";
import CARD_IMG_4 from "@/img/home_easy_4.png";
import { Image, Link } from "@chakra-ui/next-js";

export const EasyToOrderSection: React.FC = () => {
  const cards = [
    {
      img: CARD_IMG_1,
      title: "Explore Our Menu & Order Meals",
      text: "Choose your meals weekly.",
    },
    {
      img: CARD_IMG_2,
      title: "Convenient Delivery or Pickup",
      text: "Tuesday & Friday delivery.",
    },
    {
      img: CARD_IMG_3,
      title: "Easy Meal Preparation",
      text: "Ready to eat in minutes.",
    },
    {
      img: CARD_IMG_4,
      title: "Enjoy Your Meals",
      text: "Healthy meals made easy.",
    },
  ];

  return (
    <Section
      padded
      background={`linear-gradient(0deg, #EFEFF1 0%, #FDFEFF 100%)`}
    >
      <Heading
        textAlign="center"
        fontWeight={{ base: "semibold", md: "400" }}
        fontSize={{
          base: "2xl",
          md: "4xl",
        }}
        maxW="740px"
        mx="auto"
      >
        Easy to Order, Deliciously Healthy Meals Delivered Straight to Your Home
        or Pickup Location.
      </Heading>
      <Text textAlign="center" mt="4">
        Curious? Eating healthy is only a few clicks away.
      </Text>
      <Grid
        mt="16"
        templateColumns={{
          base: "repeat(2, 1fr)",
          xl: "repeat(4, 1fr)",
        }}
        gap="8"
        justifyItems="center"
        maxW="container.xl"
        mx="auto"
      >
        {cards.map((card) => (
          <Flex
            key={card.title}
            flexDir="column"
            alignItems="center"
            w={{
              base: "4xs",
              md: "2xs",
            }}
            minW="0"
            shadow="2xl"
            p="6"
            rounded="2xl"
            rowGap="2"
          >
            <Image src={card.img} alt={card.title} w="100px" />
            <Heading
              mt="4"
              fontWeight="semibold"
              fontSize="md"
              textAlign="center"
            >
              {card.title}
            </Heading>
            <Text textAlign="center" mt="auto" fontSize="xs">
              {card.text}
            </Text>
          </Flex>
        ))}
      </Grid>
      <Flex flexDir="row" justifyContent="center">
        <Link
          bg="white"
          color="black"
          mx="auto"
          my="16"
          alignSelf="center"
          size="lg"
          px="8"
          py="4"
          rounded="full"
          fontWeight="regular"
          href="/menu"
        >
          View Menu &rarr;
        </Link>
      </Flex>
    </Section>
  );
};
